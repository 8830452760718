import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Survey} from '../../model/survey/survey.model';
import {YoungAnswer} from '../../model/survey/young-answer.model';
import {AbstractWebservice} from "./abstract.webservice";

@Injectable({
  providedIn: 'root'
})
export class FseWebservice extends AbstractWebservice {
  entity = `fse`;

  constructor(protected readonly httpClient: HttpClient,
              protected  readonly httpBackend: HttpBackend) {
    super(httpClient, httpBackend);
  }
  getLastQuestionnaire() {
    return this.get<Survey>(`${this.root}/last`);
  }

  getQuestionnaire(year) {
    return this.get<Survey>(`${this.root}/${year}`);
  }

  getAllYoungAnswers(idYoung) {
    return this.get<YoungAnswer[]>(`${this.root}/${idYoung}/answers`);
  }
}
